import BaseService from "./base.service";

class ModulesService {

    modules(page = null, search = null,modeltype=null) {
        let params = [];
        if(search) {
            params.push({'key':'q', 'value':search});
        }
        if(modeltype) {
            params.push({'key':'type', 'value':modeltype});
        }
        return BaseService.apiGetRequest('modules', page, params);
    }
    get(id) {
        return BaseService.apiGetRequest('modules/'+id);
    }
    delete(modules) {
		return BaseService.apiPostRequest('modules/delete/', modules);
    }
    add(modules) {
        return BaseService.apiPostRequest('modules/add', modules);
    }
    update(id, modules) {
        return BaseService.apiPostRequest('modules/'+id, modules);
    }

    search(page=null) {
        return BaseService.apiGetRequest('modules/search', page);
    }
}
export default new ModulesService();
